import Vue from 'vue';
// import Element from 'element-ui';
import {
  Input,
  InputNumber,
  DatePicker,
  Autocomplete,
  Button,
  Form,
  FormItem,
  Select,
  Option,
  Tabs,
  TabPane,
  Switch,
  Rate,
  Progress,
  Tag,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Row,
  Col,
  Table,
  TableColumn,
  Dialog,
  Cascader,
  Pagination,
  Upload,
  Message,
  MessageBox,
  Loading,
  Carousel,
  CarouselItem,
  Image,
  Popconfirm,
  Tooltip,
  Steps,
  Step,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Icon,
  Dropdown,
  DropdownItem,
  DropdownMenu,
} from 'element-ui';
import '@/assets/css/element-variables.scss';
// @ts-ignore
import preview from 'vue-photo-preview';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import ToastRegistry from '@/components/Toast';
import Login from '@/components/Login';
// @ts-ignore
import CompleteRegistry from '@/components/CompleteInfo/index.ts';
import VueLazyload from 'vue-lazyload';
import { formatCover } from '@/util/common';
import moment from 'moment';
// const locale = require('element-ui/lib/locale/lang/zh-CN');

// Vue.use(Element, { locale });
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(DatePicker);
Vue.use(Autocomplete);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Switch);
Vue.use(Rate);
Vue.use(Progress);
Vue.use(Tag);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Dialog);
Vue.use(Cascader);
Vue.use(Pagination);
Vue.use(Upload);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Image);
Vue.use(Popconfirm);
Vue.use(Tooltip);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Icon);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);

Vue.use(Loading.directive);

Vue.use(ToastRegistry);
Vue.component(Login.name, Login);
Vue.use(CompleteRegistry);
Vue.use(VueLazyload, {
  beforeUpdate() {
    console.log('114-update');
  },
  filter: {
    webp(listener: { src: string; el: any }, options: { supportWebp: any }) {
      // console.log('115-', listener);
      // console.log('116-', listener.src);
      if (listener.src.includes('undefined')) {
        listener.src = '';
      }
      let _src = formatCover(listener.src);
      if (options.supportWebp) {
        const isCDN = /(aliyuncs.com)|(file.jufair.com)|(.jufair.com)/;
        if (isCDN.test(_src) && !_src.includes('?x-oss-process=')) {
          _src = _src?.split('?')[0];
          if (
            listener.el.className.includes('banner-img') ||
            listener.el.className.includes('not-watermark')
          ) {
            if (!_src.includes('!webp')) {
              _src += '!webp';
            }
          } else if (!_src.includes('!swebp')) {
            _src += '!swebp';
          }
        }
      }
      listener.src = _src;
    },
  },
});

if (process.client) {
  Vue.use(preview);
  Vue.use(VueAwesomeSwiper);
}

Vue.prototype.$message = Message;
Vue.prototype.$login = Login;
Vue.prototype.$confirm = MessageBox.confirm;

moment.locale('zh-CN');
