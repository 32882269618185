/* 全局方法 */
import Vue from 'vue';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import { global as myGlobal } from '~/util/store-accessor';
import { bucketCdnHost, bucketHost } from '~/util/common';

const utils = {
  install() {
    Vue.prototype.$get = _.get;
  },
};

Vue.use(utils);
Vue.filter('cover', function (value: string) {
  let parse: any = value;
  if (typeof value === 'string') {
    try {
      parse = JSON.parse(value)[0]
        .replace('http://', 'https://')
        .replace(bucketHost, bucketCdnHost);
    } catch (error) {
      parse = value;
      console.error(error);
    }
  }
  return parse;
});

Vue.filter('numFormat', function (str: string) {
  const num = parseInt(str);
  if (num >= 10000) {
    return `${(num / 10000).toFixed(1)}w`;
  }
  return num;
});

Vue.directive('webp', {
  bind(el: any) {
    const { isWebp } = myGlobal;
    // console.log('是否支持webp', isWebp, el.dataset.src, process.env.NODE_ENV);
    // 支持webp
    if (isWebp) {
      const src = _.get(el, 'dataset.src', '') || el.src;
      const isCDN = /(aliyuncs.com)|(file.jufair.com)|(.jufair.com)/;
      const hasWebp =
        src && isCDN.test(src) && !src.includes('?x-oss-process=');
      let newSrc = hasWebp ? `${src}!webp` : src;
      // 生产环境使用cdn加速域名
      if (process.env.NODE_ENV === 'production') {
        newSrc = newSrc
          .replace('http://', 'https://')
          .replace(bucketHost, bucketCdnHost);
      }

      el.src = newSrc;
      el.dataset.src = newSrc;
      el.style.opacity = 1;

      el.onerror = () => {
        if (el.src && el.src.includes('!webp')) {
          el.src = src;
        }
      };
    } else {
      const src = _.get(el, 'dataset.src', '') || el.src;
      el.src = src;
      el.style.opacity = 1;
    }
  },
});

Vue.directive('webpBg', {
  bind(el: any) {
    if (el) {
      const str = el.getAttribute('class')?.split(' ');
      str.push('webpa');
      el.setAttribute('class', str.join(' '));
    }
  },
});

Vue.prototype.setData = function (obj: Record<string, any>) {
  let keys = [];
  let val: any;
  let data: { [x: string]: any };
  Object.keys(obj).forEach((key) => {
    keys = key.split('.');
    val = obj[key];
    data = this.$data;
    keys.forEach((key2, index) => {
      if (index + 1 == keys.length) {
        this.$set(data, key2, val);
      } else if (!data[key2]) {
        this.$set(data, key2, {});
      }
      data = data[key2];
    });
  });
};

Vue.prototype.exportPDF = exportPDF;

function exportPDF(containerId: string) {
  const pdfContent: any = document.getElementById(containerId);
  const opts = {
    background: '#fff',
    taintTest: true, // 检测每张图片都已经加载完成
    useCORS: true, // 【重要】开启跨域配置
    allowTaint: true, // 允许加载跨域的图片
  };
  return new Promise((resolve) => {
    html2canvas(pdfContent, opts).then(async function (canvas: any) {
      await downloadCanvasWithMargin(canvas, resolve);
    });
  });
}

async function downloadCanvasWithMargin(
  canvas: any,
  resolve: (value: string) => void
) {
  const canvasArr = [];
  let searchParams;
  if (process.client) {
    searchParams = new URLSearchParams(location.search);
  }
  // 关闭锯齿
  const context = canvas.getContext('2d');
  context.mozImageSmoothingEnabled = false;
  context.webkitImageSmoothingEnabled = false;
  context.msImageSmoothingEnabled = false;
  context.imageSmoothingEnabled = false;

  const contentWidth = canvas.width;
  const contentHeight = canvas.height;

  // 一页pdf显示html页面生成的canvas高度;
  const pageHeight = (contentWidth / 595.28) * 841.89;
  // 未生成pdf的html页面高度
  let leftHeight = contentHeight;
  let cutStartHeight = 0;

  // 当内容未超过pdf一页显示的范围，无需分页
  if (leftHeight > pageHeight) {
    while (leftHeight > 0) {
      const cutImage = context.getImageData(
        0,
        cutStartHeight,
        contentWidth,
        pageHeight
      );

      // 创建新图片
      const newCanvas = document.createElement('canvas'); // 创建一个canvas节点
      newCanvas.width = canvas.width;
      newCanvas.height = pageHeight;
      const nContext: any = newCanvas.getContext('2d');
      nContext.mozImageSmoothingEnabled = false;
      nContext.webkitImageSmoothingEnabled = false;
      nContext.msImageSmoothingEnabled = false;
      nContext.imageSmoothingEnabled = false;
      nContext.putImageData(cutImage, 0, 0);
      canvasArr.push(newCanvas);

      leftHeight -= pageHeight;
      cutStartHeight += pageHeight;
    }
  } else {
    canvasArr.push(canvas);
  }

  // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
  const imgWidth = 510;
  const imgHeight = (510 / contentWidth) * contentHeight;

  const pdf = new jsPDF('p', 'pt', 'a4', true);

  // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
  for (let i = 0; i < canvasArr.length; i++) {
    const curCanvas = canvasArr[i];
    const img = new Image();
    if (searchParams?.get('type') === 'tianxi') {
      img.src = require('../assets/images/tianxi-header.png');
    } else {
      img.src = require('../assets/images/pdf-header.jpg'); // 'https://jufair.oss-cn-shanghai.aliyuncs.com/assets/images/pdf-header.jpg';
    }
    pdf.addImage(img, 'JPEG', 40, 30, imgWidth, 38);

    const pageData = curCanvas.toDataURL('image/png', 1.0);
    // 小于切分的高度，要换算比例高
    if (curCanvas.height < pageHeight) {
      const ratioHeight = (imgWidth / curCanvas.width) * curCanvas.height;
      pdf.addImage(pageData, 'JPEG', 40, 70, imgWidth, ratioHeight);
    } else {
      pdf.addImage(pageData, 'JPEG', 40, 70, imgWidth, imgHeight);
    }
    pdf.setDrawColor(255, 0, 0);
    pdf.setLineWidth(1);
    pdf.line(40, 810, imgWidth + 40, 810);
    if (i < canvasArr.length - 1) {
      pdf.addPage();
    }
  }

  const fileName = moment().format('YYYY-MM-DD_SSS');
  await pdf.save(`${fileName}.pdf`);
  resolve(`${fileName}.pdf`);
}
