import * as fundebug from 'fundebug-javascript';
// @ts-ignore
import 'fundebug-revideo'; // fundebug-revideo 0.7.1已更新至0.8.0
// @ts-ignore
import fundebugVue from 'fundebug-vue';
import Vue from 'vue';
import { message } from '~/util/common';

// 临时邮箱
// uolnja59421@chacuo.net
fundebug.init({
  apikey: '2f5acb3be02f3ebc9a576021a47a42e195d43a15877191373ed4a25d1667a236',
  releasestage: process.env.NODE_ENV,
  silentResource: true,
  setHttpBody: true,
  httpTimeout: 3000, // 监控超过3000毫秒的http请求
  silentDev: true, // 不收集开发环境中的错误
  filters: [
    {
      target: {
        src: /sfp.safe.baidu.com/,
      },
    },
    {
      target: {
        src: /sofire.baidu.com/,
      },
    },
    {
      target: {
        src: /miao.baidu.com/,
      },
    },
    {
      message: /Page not found-数据不存在/,
    }, // 没有数据(如:exhibition-0-0-1-11-0-10-2/ 最后一个参数是第二页)
    {
      message: /Page not found-已下架/,
    }, // 数据已下架
    {
      message: /验证码不正确/,
    },
    {
      target: {
        src: /www.madkapital.com/,
      },
    },
    {
      target: {
        src: /vue.jufair.com/,
      },
    },
    {
      name: /ErrorCaptured/,
      message: /nextTick/,
    }, // https://miao.baidu.com/abdr?_o=错误
    {
      name: /ErrorCaptured/,
      message: /mounted hook/,
    }, // 过滤表单校验提示
  ],
});

fundebugVue(fundebug, Vue);
